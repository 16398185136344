import { template as template_ba6f2da5e2f84b83ac7b8b218b0ee038 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_ba6f2da5e2f84b83ac7b8b218b0ee038(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
