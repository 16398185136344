import { template as template_0ca5a8d60f9d4e43aa9341952b7aea83 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0ca5a8d60f9d4e43aa9341952b7aea83(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
