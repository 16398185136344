import { template as template_f3c2cf2bdb0446a39169a6ec1ee4cef3 } from "@ember/template-compiler";
const FKText = template_f3c2cf2bdb0446a39169a6ec1ee4cef3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
